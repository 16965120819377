@import url(https://fonts.googleapis.com/css?family=Montserrat|Source+Code+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300&display=swap);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 200;
  letter-spacing: 1px;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 200;
}

b {
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home-page-wrapper {
  padding: 40px 20px;
}

.home-page-wrapper h1 {
  padding-left: 5px;
}

.home-page-wrapper > div {
  margin: 20px auto;
  max-width: 900px;
}

.subtle {
  color: #666;
  font-size: 80%;
}

.home-page-card {
  padding: 20px;
  box-shadow: 0 0 5px #ccc;
  background: #fafafa;
}

.home-page-card h2,
.home-page-card p {
  margin: 3px 0;
}

#mugshot img {
  width: 300px;
}

.projects-page {
  margin: auto;
  max-width: 500px;
}

.projects-page i {
  font-size: 0.8em;
}

.achievements-page {
  margin: auto;
  max-width: 500px;
}

.achievements-page i {
  font-size: 0.8em;
}

.card-wrapper a {
  color: inherit;
  text-decoration: none;
}

.card-wrapper {
  /* Positioning */

  /* Display and Box Model */
  display: flex;
  height: 350px;
  width: 800px;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 0 20px black;

  /* Other */
  background: #222d31;
}

.card-wrapper > div {
  flex-basis: 0;
  padding: 5px;
}

.card-image-wrapper {
  box-sizing: content-box !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
}

.card-image-wrapper > img {
  width: 100%;
}

.card-text-wrapper {
  box-sizing: border-box !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 3;
  padding-left: 20px !important;

  color: #aaa;
  font-family: "Source Code Pro", monospace;
}

.divider::after {
  content: "---------------------------------------------";
}

.school::after {
  content: "\00a0\00a0\00a0\00a0";
}

.work::after {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
}

.nf-black {
  color: #2e3436;
}

.nf-grey {
  /* color: rgb(98, 104, 117); */
  color: #c0bbb2;
}

.nf-red {
  /* color: #aa0000; */
  color: #ff6666;
}

.nf-green {
  /* color: #4e9a06; */
  color: #b5fa74;
}

.nf-yellow {
  /* color: #c4a000; */
  color: #ffe056;
}

.nf-blue {
  /* color: #2f5b94; */
  color: #8eb7db;
}

.nf-purple {
  /* color: #75507b; */
  color: #d567e7;
}

.nf-cyan {
  /* color: #06989a; */
  color: #74f8fa;
}

.nf-white {
  color: #d3d7cf;
}

@media only screen and (max-width: 950px) {
  .card-wrapper {
    display: block;
    height: auto;
    width: auto;
    max-width: 500px;
  }

  .card-image-wrapper {
    flex-direction: row;
  }

  .card-image-wrapper > img {
    width: 250px;
    height: 250px;
  }

  .card-text-wrapper {
    padding: 15px 3px !important;
    text-align: center;
  }

  .divider::after {
    content: "---------";
  }

  .school::after {
    content: "\A";
    white-space: pre;
  }

  .work::after {
    content: "\A";
    white-space: pre;
  }
}

.random-quote-wrapper {
  text-align: center;
  margin: 0 auto;
}

.random-quote-wrapper > div:nth-child(2) > p {
  color: grey;
}

.social-crumbs-wrapper {
  display: flex;
  justify-content: space-evenly;
  padding: 50px 0;
  max-width: 500px;
}

.social-crumbs-wrapper > div {
  text-align: center;

  color: rgba(255, 255, 255, 0.4);
  font-size: 3em;
}


