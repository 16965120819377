@import url("https://fonts.googleapis.com/css?family=Montserrat|Source+Code+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 200;
  letter-spacing: 1px;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 200;
}

b {
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
