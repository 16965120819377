.home-page-wrapper {
  padding: 40px 20px;
}

.home-page-wrapper h1 {
  padding-left: 5px;
}

.home-page-wrapper > div {
  margin: 20px auto;
  max-width: 900px;
}

.subtle {
  color: #666;
  font-size: 80%;
}

.home-page-card {
  padding: 20px;
  box-shadow: 0 0 5px #ccc;
  background: #fafafa;
}

.home-page-card h2,
.home-page-card p {
  margin: 3px 0;
}

#mugshot img {
  width: 300px;
}
