.social-crumbs-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-evenly;
  padding: 50px 0;
  max-width: 500px;
}

.social-crumbs-wrapper > div {
  text-align: center;

  color: rgba(255, 255, 255, 0.4);
  font-size: 3em;
}
