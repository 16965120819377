.card-wrapper a {
  color: inherit;
  text-decoration: none;
}

.card-wrapper {
  /* Positioning */

  /* Display and Box Model */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 350px;
  width: 800px;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 0 20px black;

  /* Other */
  background: #222d31;
}

.card-wrapper > div {
  flex-basis: 0;
  padding: 5px;
}

.card-image-wrapper {
  box-sizing: content-box !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
}

.card-image-wrapper > img {
  width: 100%;
}

.card-text-wrapper {
  box-sizing: border-box !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 3;
  padding-left: 20px !important;

  color: #aaa;
  font-family: "Source Code Pro", monospace;
}

.divider::after {
  content: "---------------------------------------------";
}

.school::after {
  content: "\00a0\00a0\00a0\00a0";
}

.work::after {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
}

.nf-black {
  color: #2e3436;
}

.nf-grey {
  /* color: rgb(98, 104, 117); */
  color: #c0bbb2;
}

.nf-red {
  /* color: #aa0000; */
  color: #ff6666;
}

.nf-green {
  /* color: #4e9a06; */
  color: #b5fa74;
}

.nf-yellow {
  /* color: #c4a000; */
  color: #ffe056;
}

.nf-blue {
  /* color: #2f5b94; */
  color: #8eb7db;
}

.nf-purple {
  /* color: #75507b; */
  color: #d567e7;
}

.nf-cyan {
  /* color: #06989a; */
  color: #74f8fa;
}

.nf-white {
  color: #d3d7cf;
}

@media only screen and (max-width: 950px) {
  .card-wrapper {
    display: block;
    height: auto;
    width: auto;
    max-width: 500px;
  }

  .card-image-wrapper {
    flex-direction: row;
  }

  .card-image-wrapper > img {
    width: 250px;
    height: 250px;
  }

  .card-text-wrapper {
    padding: 15px 3px !important;
    text-align: center;
  }

  .divider::after {
    content: "---------";
  }

  .school::after {
    content: "\A";
    white-space: pre;
  }

  .work::after {
    content: "\A";
    white-space: pre;
  }
}
